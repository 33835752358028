var prodcat = prodcat || {};

(function ($) {
  var _templateClass = '.sku-list-sizes-v1';

  Drupal.behaviors.skuListSizesV1 = {
    attach: function (context) {
      var $templateList = $('.js-list-sizes-select', context);
      // @TODO: update this function to account for sku-base-id.
      // It should probably just default to the 1st item in that case.
      var _activateButton = function ($skuListItems, skuBaseId) {
        if (!!$skuListItems.length && !!skuBaseId) {
          $skuListItems
            .removeClass('active')
            .filter('[data-sku-base-id=' + skuBaseId + ']')
            .addClass('active');
        }
      };

      $(_templateClass, context)
        .once('sku-list-size')
        .each(function () {
          var $template = $(this);
          var $skuListItems = $template.find('.js-sku-list-sizes-button');
          var skuBaseId = $template.closest('.js-product').data('sku-base-id');

          // Set initial active state
          $('select.js-list-sizes-select', $template).val(skuBaseId);
          $('select.js-list-sizes-select.selectBox-attached', $template).selectBox('value', skuBaseId);
          _activateButton($skuListItems, skuBaseId);

          $skuListItems.once('sku-list-item-click').on('click', function (e) {
            e.preventDefault();

            var $item = $(this);
            var skuBaseId = $item.attr('data-sku-base-id');

            if (!skuBaseId) {
              return;
            }

            _activateButton($skuListItems, skuBaseId);

            $item.closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
          });
        });

      // Set active state on sku change.
      $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
        var $skuSizeButtons = $('.js-sku-list-sizes-button', $(this));
        $('select.js-list-sizes-select', this).val(skuBaseId);
        $('select.js-list-sizes-select.selectBox-attached', this).selectBox('value', skuBaseId);

        _activateButton($skuSizeButtons, skuBaseId);
      });

      $templateList.on('change', function () {
        var skuId = $(this).val();
        var skuVal = prodcat.data.getSku(skuId);
        var skuBaseId;

        if (!!skuVal) {
          skuBaseId = skuVal.SKU_BASE_ID;
          $(this).closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
        }
      });
    }
  };

  // Update size select for inventory status updates
  $(document).on('inv_status_data:updated inv_status_data:finished', '.js-product', function () {
    var $product = $(this);
    var $sizeList = $(_templateClass, $product);

    // Note: dO not use once() here since we want it to respond to multiple sku updates
    $('.js-sku-list-sizes-button', $sizeList).each(function () {
      var $button = $(this);
      var skuBaseId = $button.data('sku-base-id');
      var skuData = prodcat.data.getSku(skuBaseId); // note: this method handles base id's too

      if (!skuData) {
        return;
      }

      if (
        parseInt(skuData.isOrderable) === 0 ||
        parseInt(skuData.isShoppable) === 0 ||
        parseInt(skuData.isSoldOut) === 1
      ) {
        $button.addClass('button--disabled');
      } else {
        $button.removeClass('button--disabled');
      }
    });
  });
})(jQuery);
